:root {
  --corPrimaria: rgb(106, 49, 137);
  --corSecundaria: rgb(255, 192, 92);
  --corSecundariaLight: rgba(255, 192, 92, 0.2);
}

::-webkit-scrollbar{
  width: 4px;
}
::-webkit-scrollbar-thumb{
  background-color:var(--corPrimaria);
  
}

::selection {
  background: var(--corSecundaria) !important;
}
.ant-input:active,
.ant-input:focus,
.ant-input:hover,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover,
.ant-select:active,
.ant-select-selector:active,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-radio-inner {
  border-color: var(--corSecundaria) !important;
}
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  color: var(--corSecundaria) !important;
}

.ant-input:focus,
.ant-select:active,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  -webkit-box-shadow: 0 0 0 2px var(--corSecundariaLight) !important;
  box-shadow: 0 0 0 2px var(--corSecundariaLight) !important;
  border-color: var(--corSecundaria) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--corSecundariaLight) !important;
}
.ant-btn-primary:not(.ant-btn-dangerous):enabled {
  background: var(--corSecundaria) !important;
  border-color: var(--corSecundaria) !important;
}
.ant-btn-primary[enabled]:active,
.ant-btn-primary:focus,
.ant-btn-primary:not(.ant-btn-primary:disabled):hover {
  color: white !important;
}
.ant-btn-primary:hover {
  opacity: 0.8;
}
.ant-btn-secondary:not(.ant-btn-dangerous):enabled {
  background: var(--corPrimaria) !important;
  border-color: var(--corPrimaria) !important;
  color: white;
}
.ant-btn-secondary[enabled]:active,
.ant-btn-secondary:focus,
.ant-btn-secondary:not(.ant-btn-secondary:disabled):hover {
  color: white !important;
}
.ant-btn-secondary:hover {
  opacity: 0.8;
}
a:not(.ant-btn) {
  color: var(--corSecundaria) !important;
}
a:active,
a:focus,
a:hover {
  opacity: 0.8;
}
.ant-radio-inner:after,
.ant-radio-inner:checked:after {
  border-color: var(--corSecundaria) !important;
  background-color: var(--corSecundaria) !important;
}

.Submenu-class {
  margin-top: 100px !important;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif;
}
@media (min-width: 955px) {
  * {
    border-radius: 2px;
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
    border-radius: 0 2px 2px 0;
  }
}
img {
  border-radius: 2px;
}

.shadow {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}
.shadow2 {
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
}

.pulse {
  box-shadow: 0 0 0 var(--corSecundariaLight);
  animation: pulse 1s infinite;
}
.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 2;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--corSecundariaLight);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--corSecundariaLight);
    box-shadow: 0 0 0 0 var(--corSecundariaLight);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
    box-shadow: 0 0 0 10px rgba(106, 49, 137, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
    box-shadow: 0 0 0 0 rgba(106, 49, 137, 0);
  }
}

.animated-linear {
  -webkit-transition: background-color 270ms linear;
  -ms-transition: background-color 270ms linear;
  transition: background-color 270ms linear;
  transition: all ease-in-out 270ms;
}

.fade-enter {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-appear {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}
.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide {
  position: absolute;
  -webkit-animation: slide 0.25s;
  animation: slide 0.25s;
}

@keyframes slide {
  0% {
    right: -300px;
  }

  100% {
    right: 0px;
  }
}

.slideReverse {
  position: absolute;
  -webkit-animation: slideReverse 0.25s;
  animation: slideReverse 0.25s;
}

@keyframes slideReverse {
  100% {
    right: 0px;
  }

  0% {
    right: -300px;
  }
}

@media (max-width: 955px) {
  html,
  body {
    height: calc(100vh - 56px) !important;
    width: 100%;
    

  }


}
