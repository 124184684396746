@media (max-width: 955px) {
  .ant-drawer-content {
    border-radius: 16px 16px 0px 0px !important;
  }

  .ant-modal-content {
    border-radius: 10px !important;
  }
  .ant-modal-header {
    border-radius: 10px !important;
  }
}
